import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

shopifyDawnGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').location = {
  replace: '.search-bar-header .search-bar',
};
shopifyDawnGenericDefaults.Widgets.find((w) => w.name === 'SearchBoxDialogButton').location = {
  replace: '.header__icons .mobile-search-icon',
  class: 'cm_desktop-hide header__icon',
};

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
};
