
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'viewBox': '0 0 36 37',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'className': 'icon icon-search',
                'id': 'icon-search'
            }, _createElement('path', {
                'd': 'M21.0625 17.3438C21.0625 15.7852 20.2148 14.3633 18.875 13.5703C17.5078 12.7773 15.8398 12.7773 14.5 13.5703C13.1328 14.3633 12.3125 15.7852 12.3125 17.3438C12.3125 18.9297 13.1328 20.3516 14.5 21.1445C15.8398 21.9375 17.5078 21.9375 18.875 21.1445C20.2148 20.3516 21.0625 18.9297 21.0625 17.3438ZM20.2148 21.8281C19.2305 22.5938 18 23.0312 16.6875 23.0312C13.543 23.0312 11 20.4883 11 17.3438C11 14.2266 13.543 11.6562 16.6875 11.6562C19.8047 11.6562 22.375 14.2266 22.375 17.3438C22.375 18.6836 21.9102 19.9141 21.1445 20.8984L24.8086 24.5352C25.0547 24.8086 25.0547 25.2188 24.8086 25.4648C24.5352 25.7383 24.125 25.7383 23.8789 25.4648L20.2148 21.8281Z',
                'fill': 'white'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]